import React from "react"
import {
    NavLink
} from "react-router-dom";
import styles from './Bar.module.css'
import logo from '../img/logo.png'
function Bar({ withLogo = true }) {
    return (
        <div className={styles.fullbar}>
            {withLogo && (
                <div>
                    <a href="/"><img className={styles.logo} src={logo} alt="logo" width="200" height="200" /></a>
                </div>
            )}
            <nav className={styles.navbar} id="bar">
                <NavLink activeClassName={styles.active} className={styles.menuitem} to='/about' title="about">about</NavLink>
                <div className = {styles.square}></div>
                <NavLink activeClassName={styles.active} className={styles.menuitem} to='/rules' title="rules">rulebook</NavLink>
                <div className = {styles.square}></div>
                <NavLink activeClassName={styles.active} className={styles.menuitem} to='/highlights' title="highlights">players</NavLink>
                <div className = {styles.square}></div>
                <NavLink activeClassName={styles.active} className={styles.menuitem} to='/contact' title="contact">contact</NavLink>
            </nav>
        </div>
    )
}

export default Bar
