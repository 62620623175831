import React from "react"
import styles from "./Highlights.module.css"

import Feed from "./Feed.js"

import { players } from '../data/players.json'


function Highlights() {
    return (
        <div className = {styles.container}>
            {/* I (Ben) am loading this CSS framework called TailwindCSS that makes it way faster for me to write CSS, just for this page.  */}
            <link rel="stylesheet" href="https://unpkg.com/tailwindcss@2.2.7/dist/tailwind.min.css" />

            <div class="text-white space-y-16 mb-24 mx-4" style={{ fontFamily: 'Poppins, sans-serif' }}>
                <div class="max-w-screen-md mx-auto border-2 border-gray-300 p-4">
                    <div class="mb-4 space-y-1">
                        <p class="font-black text-xl">Target Order</p>
                        <p class="text-gray-300">
                            This was the randomly generated order of targets for the 2021 game.
                            Each of the 162 players was initially assigned the person below them, and moved down the list if they eliminated that first target.
                        </p>
                    </div>
                    <div class="font-mono">
                        {players.map(name => (
                            <p key={name}>{name}</p>
                        ))}
                    </div>
                </div>

                <Feed className = {styles.blub}/>
            </div>
        </div>
    )
}

export default Highlights
