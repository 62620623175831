import React from "react"

import killIcon from "../img/killIcon.png"

// This feed no longer needs to be live data, so I just downloaded it.
import events from '../data/status-feed.json'


function Feed() {
    return (
        <div class="max-w-screen-md mx-auto border-2 border-gray-300 p-4">
            <p class="font-black text-xl mb-4">Kill Feed {events.length > 0 && <span class="font-normal">({events.length} people killed)</span>}</p>

            <div class="space-y-1">
                {events.map(person =>
                    <div key={person.name} class="flex flex-row items-center space-x-3">
                        <img src={killIcon} alt="" class="h-2.5" />
                        <span class="text-sm text-gray-100">
                            {person.name}{person.name.includes('Nick Favazzo') ? '*' : ''}
                            {' '}
                            ({new Date(person.timestamp).toLocaleString('en-US', { timeZone: 'America/New_York', month: 'numeric', day: 'numeric'})})
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Feed
